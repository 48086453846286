//@ts-nocheck
import ModalResponsive from "../components/modal-responsive";
import ShippingDetails from "../components/shipping-details";
import React, { useState, useEffect, useCallback } from "react";
import Client from "shopify-buy";
import Store from "../utils/store";
import { getLocalState } from "../helper/local-storage";
import { CART_PRODUCTS } from "./local-cart-provider";
import { globalErrorHandler } from "../utils/errorHandler";

export const CartContext = React.createContext();

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN, // Load from env
  domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`, // Load from env
});

const CartProvider = ({ children }) => {
  let initialStoreState = {
    client,
    updating: false,
    checkout: { lineItems: [] },
    products: [],
    shop: {},
  };

  const [store, updateStore] = useState(initialStoreState);
  const [initialize, setIntialize] = useState(false);
  const [shippingVisible, setShippingVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Store.add_item("shipping_modal", {
      is_open: () => shippingVisible,
      open: () => setShippingVisible(true),
      close: () => setShippingVisible(false),
    });
    const initializeCheckout = async () => {
      const setCheckoutInState = (checkout) => {
        updateStore((prevState) => {
          return { ...prevState, checkout };
        });
      };
      const createNewCheckout = () => store.client.checkout.create();
      const newCheckout = await createNewCheckout();
      setCheckoutInState(newCheckout);
    };

    initializeCheckout();
  }, [initialize]);

  return (
    <CartContext.Provider
      value={{
        store,
        addToCartAndCheckout: async () => {
          try {
            setLoading(true);
            const { checkout, client } = store;
            const checkoutId = checkout.id;
            updateStore((prevState) => {
              return { ...prevState, updating: true };
            });
            const products = getLocalState(CART_PRODUCTS);
            const lineItemsToUpdate = [];
            const shippingDetails = Store.data.shippingDetails;
            const { location } = shippingDetails;

            products.forEach((product) => {
              lineItemsToUpdate.push({
                variantId: product.variantId,
                quantity: product.quantity,
              });
            });

            await client.checkout.updateAttributes(checkoutId, {
              customAttributes: [
                {
                  key: "shippingDetails",
                  value: JSON.stringify({
                    ...shippingDetails,
                    timezoneOffset: new Date().getTimezoneOffset(),
                  }),
                },
              ],
            });

            await client.checkout.updateShippingAddress(checkoutId, {
              address1: location.address,
              city: location.city || "Karachi",
              country: location.country || "Pakistan",
              province: location.province || "Sindh",
              zip: location.zip || "75800",
              firstName: "First Name",
              lastName: "Last Name",
            });

            client.checkout
              .addLineItems(checkoutId, lineItemsToUpdate)
              .then((checkout) => {
                // windowReference.location = checkout.webUrl;
                window.location = checkout.webUrl;
                setIntialize(Date.now());
                setLoading(false);
                setShippingVisible(false);
                updateStore((prevState) => {
                  return {
                    ...prevState,
                    checkout,
                    updating: false,
                  };
                });
              });
          } catch (e) {
            globalErrorHandler(e);
          }
        },
      }}
    >
      {children}
      <ModalResponsive
        isOpen={shippingVisible}
        onAttemptClose={() => setShippingVisible(false)}
        closeOnClickOutside={false}
      >
        <ShippingDetails
          loading={loading}
          onSubmit={(val) => {
            Store.add_item("shippingDetails", val);
            Store.data.perform_checkout();
          }}
        />
      </ModalResponsive>
    </CartContext.Provider>
  );
};

export default CartProvider;
