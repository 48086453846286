//@ts-nocheck
import React, { useState, useCallback, useEffect } from "react";
import Geo from "../../utils/geolocation";
import { globalErrorHandler } from "../../utils/errorHandler";
import { MdLocationOn, MdMyLocation, MdRoom } from "react-icons/md";
import debounce from "lodash.debounce";
import { Button, Select, Typography, Spin } from "antd";
import "antd/dist/antd.css";

let map;

const MyLocButton = ({ onClick, color }) => (
  <Button
    style={{
      position: "absolute",
      top: "16px",
      right: "12px",
      zIndex: 1,
      width: "42px",
      height: "42px",
      boxShadow: "1px 1px 6px #aaa",
      backgroundColor: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    }}
    shape="circle"
    icon={<MdMyLocation style={{ color }} />}
    onClick={onClick}
  />
);

const MapPointer = ({ color, size = 40 }) => {
  return (
    <MdRoom
      style={{
        color,
        width: `${32}px`,
        height: `${32}px`,
        position: "absolute",
        top: `calc(50% - ${size / 2}px)`,
        left: `calc(50% - ${size / 2}px)`,
      }}
    />
  );
};

export default function LocationSelector({
  onLocationChange = ({ address, city, lat, lng }) => {},
  primaryColor = "#00bd51",
  initialLocation,
  required,
  mapHeight = "420px",
}) {
  const [places, setPlaces] = useState([]);
  const [address, setAddress] = useState(
    initialLocation ? initialLocation.address : undefined
  );
  const [currLoc, setCurrLoc] = useState(
    initialLocation
      ? Geo.get_region(initialLocation.lat, initialLocation.lng)
      : Geo.default_region
  );
  const [loading, setLoading] = useState(false);

  const moveToMyLoc = useCallback(() => {
    Geo.get_location({})
      .then((loc) => {
        setCurrLoc({ lat: loc.lat, lng: loc.lng });
        moveMap(loc.lat, loc.lng);
      })
      .catch(globalErrorHandler);
  });

  useEffect(() => {
    let listener;
    (async () => {
      map = await Geo.init_map("gmap-container", currLoc, 13, {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      });
      if (!initialLocation) moveToMyLoc();

      listener = map.addListener(
        "center_changed",
        debounce(() => {
          const center = map.getCenter();
          const lat = center.lat();
          const lng = center.lng();
          Geo.reverse_geocode(lat, lng)
            .then((res) => {
              setAddress(res.address);
              onLocationChange(res);
            })
            .catch(globalErrorHandler);
        }, 1500)
      );
    })();

    return () => {
      Geo.google?.maps.event.removeListener(listener);
    };
  }, []);

  const moveMap = (lat, lng) => {
    map && map.panTo(new Geo.google.maps.LatLng(lat, lng));
    if (places.length) setPlaces([]);
  };

  const onChangeText = useCallback(
    debounce((text) => {
      const { lat, lng } = currLoc;
      if (text.length >= 3) {
        setLoading(true);
        Geo.auto_complete_search(
          { text, lat, lng },
          (places) => {
            setLoading(false);
            setPlaces(places);
          },
          (e) => {
            setLoading(false);
            globalErrorHandler(e);
          }
        );
      }
      setAddress(text);
    }),
    1500
  );

  const onPlaceSelect = useCallback((id) => {
    Geo.fetch_place(id).then(moveMap).catch(globalErrorHandler);
    setPlaces([]);
    setLoading(false);
  });

  return (
    <div style={{ width: "100%" }}>
      <Select
        value={address}
        placeholder="Search Location"
        filterOption={false}
        onSearch={onChangeText}
        notFoundContent={loading ? <Spin size="small" /> : null}
        onChange={onPlaceSelect}
        style={{ width: "100%" }}
        showSearch
      >
        {places.map((place) => (
          <Select.Option key={place.id} value={place.id}>
            <div style={{ alignItems: "center", display: "flex" }}>
              <MdLocationOn
                style={{
                  marginRight: 8,
                }}
              />
              <div style={{ display: "inline-flex", flexDirection: "column" }}>
                <Typography.Text style={{ fontWeight: "bold" }}>
                  {place.name}
                </Typography.Text>
                <Typography.Text style={{ fontSize: 12, color: "#555" }}>
                  {place.fullName}
                </Typography.Text>
              </div>
            </div>
          </Select.Option>
        ))}
      </Select>
      <div style={{ position: "relative", marginTop: "16px" }}>
        <div style={{ height: mapHeight }} id="gmap-container"></div>
        <MyLocButton color={primaryColor} onClick={moveToMyLoc} />
        <MapPointer color={primaryColor} />
      </div>
    </div>
  );
}
