//@ts-nocheck
import React, { useState, useCallback } from "react";
import LocationSelector from "../map-location-selector";
import { Select } from "antd";
import useWindowSize from "../../hooks/useWindowSize";

const SHIFTS = [
  "9 am - 12:30 pm",
  "1 pm - 3:30 pm",
  "3:30 pm - 6:30 pm",
  "7 pm - 9 pm",
];

function clock12ToTime(c) {
  c = c.trim();
  let pm = 0;
  let index = c.indexOf("am");
  if (index === -1) {
    index = c.indexOf("pm");
    pm = 1;
  }

  const t = c.slice(0, index).split(":");
  return {
    h: (+t[0] % 12) + pm * 12,
    m: +t[1] || 0,
    s: +t[2] || 0,
  };
}

function ShiftSelector({ onChange, value, shifts = [], style }) {
  return (
    <Select
      value={value}
      style={{ width: "100%", marginTop: 16, ...style }}
      onChange={onChange}
    >
      {shifts.map((shift) => (
        <Select.Option key={shift} value={shift}>
          {shift}
        </Select.Option>
      ))}
    </Select>
  );
}

export default function ShippingDetails({
  onSubmit = () => {},
  loading = false,
}) {
  const [location, setLocation] = useState();
  const [shift, setShift] = useState(SHIFTS[2]);

  const windowSize = useWindowSize();
  const isMobile = windowSize < 768;

  return (
    <div
      style={{
        padding: !isMobile ? 30 : 0,
        width: !isMobile ? "60vw" : "calc(100vw - 60px)",
        paddingTop: 30,
        maxWidth: 800,
        minWidth: 250,
        margin: "auto",
      }}
    >
      <h1>Enter Shipping Details</h1>

      <LocationSelector
        mapHeight={isMobile ? "360px" : "420px"}
        onLocationChange={setLocation}
      />
      <ShiftSelector onChange={setShift} value={shift} shifts={SHIFTS} />

      <div
        style={{
          cursor: "pointer",
          marginTop: 24,
          ...(isMobile
            ? {
                position: "absolute",
                bottom: "30px",
                left: "30px",
                right: "30px",
                width: "calc(100vw - 60px)",
              }
            : {}),
        }}
        onClick={() => {
          const [from, to] = shift.split("-");
          onSubmit({
            location,
            shift: { from: clock12ToTime(from), to: clock12ToTime(to) },
          });
        }}
        className="css-button"
      >
        {loading ? "Loading..." : "Proceed To Checkout"}
      </div>
    </div>
  );
}
