//@ts-nocheck

function add_item(key, value) {
  Store.data[key] = value;
}

function remove_item(key) {
  delete Store.data[key];
}

//cart_drawer
const Store = {
  add_item,
  remove_item,
  data: {},
};

export default Store;
