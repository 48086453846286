//@ts-nocheck
import React, { useState, useEffect, useCallback } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { CloseOutlined } from "@ant-design/icons";

function CloseButton({ onClose, color = "#fff", top = 44, right = 32 }) {
  return (
    <div
      style={{
        display: "inline-block",
        position: "absolute",
        top: `${top}px`,
        right: `${right}px`,
        zIndex: 1000,
      }}
    >
      <CloseOutlined style={{ fontSize: "24px", color }} onClick={onClose} />
    </div>
  );
}

export default function ModalResponsive({
  isOpen = false,
  onAttemptClose = () => {},
  closeOnClickOutside = true,
  closeOnEscapeKey = true,
  animationDelayMs = 400,
  zIndex = 999,
  children,
  backdropPercent = 50,
}) {
  const windowSize = useWindowSize();
  const isMobile = windowSize < 768;
  const [effects, setEffects] = useState({ scale: 0, bg: 0, top: "100vh" });
  const [closed, setClosed] = useState(!isOpen);

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      e.stopPropagation();
      if (e.key === "Escape" && closeOnEscapeKey) onAttemptClose();
    });
  }, []);

  useEffect(() => {
    if (!closed) {
      setEffects({ scale: 1, bg: backdropPercent / 100, top: 0 });
    }
  }, [closed]);

  useEffect(() => {
    if (isOpen) {
      setClosed(false);
    } else {
      setEffects({ scale: 0, bg: 0, top: "100vh" });
      setTimeout(() => {
        setClosed(true);
      }, animationDelayMs);
    }
  }, [isOpen]);

  function onOuterClick() {
    if (closeOnClickOutside && !isMobile) onAttemptClose();
  }

  function onInnerCick(e) {
    e.stopPropagation();
  }

  return (
    !closed &&
    (isMobile ? (
      <div
        onClick={onOuterClick}
        style={{
          position: "fixed",
          top: effects.top,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex,
          backgroundColor: "#fff",
          transition: `all ${animationDelayMs}ms`,
          overflow: "auto",
        }}
      >
        <CloseButton
          top={24}
          right={24}
          onClose={onAttemptClose}
          color="#777"
        />
        {children}
      </div>
    ) : (
      <div
        onClick={onOuterClick}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex,
          backgroundColor: `rgba(0,0,0, ${effects.bg})`,
          display: "grid",
          placeItems: "center",
          transition: `all ${animationDelayMs}ms`,
          overflow: "auto",
        }}
      >
        <div
          onClick={onInnerCick}
          style={{
            backgroundColor: "#fff",
            display: "inline-block",
            transform: `scale(${effects.scale})`,
            transition: "all 0.4s",
          }}
        >
          {children}
        </div>
        <CloseButton onClose={onAttemptClose} color="#fff" />
      </div>
    ))
  );
}
